// @see: https://chakra-ui.com/docs/theming/theme#colors
export const colors = {
    oriGrayscale: {
        50: '#eaeaea',
        100: '#d0d0d0' /* logo */,
        200: '#a9a9a9',
        300: '#868686',
        400: '#6c6c6c',
        500: '#525252',
        600: '#4b4b4b',
        700: '#414141',
        800: '#383838',
        900: '#272727' /* logo */,
    },
    oriOrange: {
        50: '#fcece2',
        100: '#f6d1b7',
        200: '#f1b287',
        300: '#eb9356',
        400: '#e67b32',
        500: '#e2640e' /* logo */,
        600: '#df5c0c',
        700: '#da520a',
        800: '#d64808',
        900: '#cf3604',
    },
    oriBlue: {
        50: '#e1e7ea',
        100: '#b3c4cb',
        200: '#819ca9',
        300: '#4e7486',
        400: '#28576c',
        500: '#023952',
        600: '#02334b',
        700: '#012c41',
        800: '#012438',
        900: '#011728',
    },
    oriTeal: {
        50: '#e1ecec',
        100: '#b3d0cf',
        200: '#81b1af',
        300: '#4f928f',
        400: '#297a77',
        500: '#03635f',
        600: '#035b57',
        700: '#02514d',
        800: '#024743',
        900: '#013532',
    },
    massBayBlue: {
        50: '#e0e6eb',
        100: '#b3c0ce',
        200: '#8096ae',
        300: '#4d6b8d',
        400: '#264c74',
        500: '#002c5c',
        600: '#002754',
        700: '#00214a',
        800: '#001b41',
        900: '#001030',
    },
    massBayGreen: {
        50: '#edf1e9',
        100: '#d2dbc8',
        200: '#b4c4a4',
        300: '#95ac7f',
        400: '#7f9a63',
        500: '#688848',
        600: '#608041',
        700: '#557538',
        800: '#4b6b30',
        900: '#3a5821',
    },
    massBayLightBlue: {
        50: '#e0f1f7',
        100: '#b3dceb',
        200: '#80c4dd',
        300: '#4daccf',
        400: '#269bc5',
        500: '#0089bb',
        600: '#0081b5',
        700: '#0076ac',
        800: '#006ca4',
        900: '#005996',
    },
    massBayMediumBlue: {
        50: '#e5ecf2',
        100: '#bed0df',
        200: '#93b0c9',
        300: '#6790b3',
        400: '#4779a3',
        500: '#266193',
        600: '#22598b',
        700: '#1c4f80',
        800: '#174576',
        900: '#0d3364',
    },
    massBayDeepNavyBlue: {
        50: '#e1e2e6',
        100: '#b3b7c2',
        200: '#808799',
        300: '#4d5770',
        400: '#273351',
        500: '#010f32',
        600: '#010d2d',
        700: '#010b26',
        800: '#01081f',
        900: '#000413',
    },
    massBaySpringGreen: {
        50: '#f5f8f1',
        100: '#e6eddd',
        200: '#d5e1c6',
        300: '#c4d5af',
        400: '#b7cc9d',
        500: '#aac38c',
        600: '#a3bd84',
        700: '#99b579',
        800: '#90ae6f',
        900: '#7fa15c',
    },
    massBayYellowGold: {
        50: '#fef8e0',
        100: '#fcedb3',
        200: '#fae280',
        300: '#f8d64d',
        400: '#f7cd26',
        500: '#f5c400',
        600: '#f4be00',
        700: '#f2b600',
        800: '#f0af00',
        900: '#eea200',
    },
    massBayRed: {
        50: '#f4e4e7',
        100: '#e4bcc2',
        200: '#d28f9a',
        300: '#bf6272',
        400: '#b24153',
        500: '#a41f35',
        600: '#9c1b30',
        700: '#921728',
        800: '#891222',
        900: '#780a16',
    },
    massBayRedOrange: {
        50: '#f5e9e5',
        100: '#e7c7be',
        200: '#d7a292',
        300: '#c67d66',
        400: '#ba6146',
        500: '#ae4525',
        600: '#a73e21',
        700: '#9d361b',
        800: '#942e16',
        900: '#841f0d',
    },

    // oriGreen: {
    //     50: '#e1eceb',
    //     100: '#b3d0cd',
    //     200: '#81b1ab',
    //     300: '#4f9289',
    //     400: '#297a70',
    //     500: '#036357',
    //     600: '#035b4f',
    //     700: '#025146',
    //     800: '#02473c',
    //     900: '#01352c',
    // },
}
